import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';

import './index.css';
import {PrivacyPolicyPage} from './pages/privacypolicy.js'
import {TermsPage} from './pages/terms.js'


import Logo from './logo.svg';


const navbar = (
  <div className="container-navbar">
    <nav>
      <img src={Logo} alt="Logo" />
      <ul>
        
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/portfolio">Portfolio</Link>
        </li>
        <li>
          <Link to="/jobs">Jobs</Link>
        </li>
      </ul>
    </nav>
  </div>
);
const emailForm = (
  <div className="subscribe-form">
    <div className="container">
      <input type="email" placeholder="Type your email..." />
      <button>Subscribe to our updates</button>
    </div>
  </div>
);

const endPageInformation = (
  <div className="end-page-information">
    <p>
      Software is eating the world; AI is eating the software
    </p>
    <p>
      &copy; 2023 h15n Ventures
    </p>
  </div>
);

class HomePage extends React.Component {
  render() {

    const mainContent = <div className="main-content">

      <p>
        At h15n Ventures, we invest in ideas that can compound; strive for the highest productivity achieavable; we are bold and daring
      </p>

    </div>;
    return (
      <div className="column">
        {navbar}
        <div className="row-container">
          <div className="container">
            {mainContent}
          </div>
          <div className="container">
            {emailForm}
          </div>
        </div>
        <div className="container-endpage">
          {endPageInformation}
        </div>
      </div>

    );
  }
}

class JobsPage extends React.Component {
  render() {
    const mainContent = <div className="main-content">

      <p>
        No jobs at the moment
      </p>

    </div>;
    return (
      <div className="column">
        {navbar}
        <div className="row-container">
          <div className="container">
            {mainContent}
          </div>
          <div className="container">
            {emailForm}
          </div>
        </div>
        <div className="container-endpage">
          {endPageInformation}
        </div>
      </div>
    );
  }
}

class PortfolioPage extends React.Component {
  render() {
    const mainContent = <div className="main-content">

      <p>
        
      </p>

    </div>;
    return (
      <div className="column">
        {navbar}
        <div className="row-container">
          <div className="container">
            {mainContent}
          </div>
          <div className="container">
            {emailForm}
          </div>
        </div>
        <div className="container-endpage">
          {endPageInformation}
        </div>
      </div>
    );
  }
}




class SupportPage extends React.Component {
  render() {
    const mainContent = <div className="important-content">

      <p>
        Support
      </p>

    </div>;
    return (
      <div className="column">
        {navbar}
        <div className="row-container">
          <div className="container">
            {mainContent}
          </div>
          <div className="container">
            {emailForm}
          </div>
        </div>
        <div className="container-endpage">
          {endPageInformation}
        </div>
      </div>
    );
  }
}




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/jobs" element={<JobsPage />} />
        <Route path="/portfolio" element={<PortfolioPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/terms" element={<TermsPage />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);



class NeonButton extends React.Component {
  render() {
    return (
      <button className="neon-button">Click Me!</button>
    );
  }
}
